const color = {
    highlight: "#b893ed",
    highlightActive: "#965fe7",
    text: "#000",
};

const char = {
    arrowRight: "→",
    arrowLeft: "←",
};

const spacing = {
    base: 12,
};

export const variables = {
    color,
    spacing,
    char,
};
