export const hoverText = () =>
    Array.from(document.querySelectorAll("[data-tip]")).forEach((el) => {
        let tip = document.createElement("div");

        tip.classList.add("tooltip");
        tip.innerText = el.getAttribute("data-tip");
        let delay = el.getAttribute("tip-delay");

        if (delay) {
            tip.style.transitionDelay = delay + "s";
        }

        tip.style.transform =
            "translate(" +
            (el.hasAttribute("tip-left") ? "calc(-100% - 5px)" : "15px") +
            ", " +
            (el.hasAttribute("tip-top") ? "-100%" : "0") +
            ")";
        el.appendChild(tip);

        (el as any).onmousemove = (e: any) => {
            tip.style.left = e.clientX + "px";
            tip.style.top = e.clientY + "px";
        };
    });
