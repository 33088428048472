import { h, FunctionComponent as F } from "preact";

interface IProps {
    text1: string;
    text2: string;
} 

const Description: F<IProps> = (props) => (
    <div className="p-description-container">
        <p className="p-description u-bolder">{props.text1}</p>
        <p className="p-description">{props.text2}</p>
    </div>
);

export default Description;
