import WaveSurfer from "wavesurfer.js"
import { h, FunctionComponent as F, Fragment } from "preact"
import { useEffect, useRef, useState } from "preact/hooks"
import { variables } from "../theme/variables"
import LoadingSpinner from "./LoadingSpinner"

interface IProps {
    audio: string
    identifier: string
    waveColor?: boolean
}

// TODO: create random hash for ID to make multiple instances possible
const AudioPlayer: F<IProps> = (props) => {
    const [loading, setLoading] = useState(true)
    const wavesurfer = useRef<WaveSurfer>(null)

    const [playing, setPlaying] = useState(false)

    useEffect(() => {
        wavesurfer.current = WaveSurfer.create({
            container: `#${props.identifier}`,
            waveColor: props.waveColor
                ? variables.color.highlight
                : variables.color.text,
            progressColor: props.waveColor
                ? variables.color.highlightActive
                : variables.color.highlight,
            barWidth: 3,
        })

        return () => wavesurfer.current.destroy()
    }, [])

    useEffect(() => {
        wavesurfer.current.load(props.audio)
        wavesurfer.current.on("ready", () => {
            setLoading(false)
        })

        wavesurfer.current.on("play", () => {
            setPlaying(true)
        })

        wavesurfer.current.on("pause", () => {
            setPlaying(false)
        })
    }, [])

    const toggle = () => wavesurfer.current.playPause()

    return (
        <Fragment>
            <div className="audio-player">
                <div className="audio-player-button-wrapper">
                    <button className="audio-player-button" onClick={toggle}>
                        {playing ? "Pause" : "Play"}
                    </button>
                </div>

                {loading && <LoadingSpinner />}

                {<div id={props.identifier} onClick={toggle} />}
            </div>
        </Fragment>
    )
}

export default AudioPlayer
