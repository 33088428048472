import { render, h } from "preact";
import Router from "preact-router";
import AsyncRoute from "preact-async-route";
import Home from "./pages/Home";
import { createHashHistory } from "history";
import { ROUTES } from "./shared/routes";
import "./styles/index.css";
import "./scripts/text.ts";

const App = () => (
    <Router history={createHashHistory()}>
        <Home path={ROUTES.home} />

        <AsyncRoute
            path={ROUTES.galerie}
            getComponent={() =>
                import("./pages/Galerie").then((module) => module.default)
            }
        />

        <AsyncRoute
            path={ROUTES.about}
            getComponent={() =>
                import("./pages/About").then((module) => module.default)
            }
        />

        <AsyncRoute
            path={ROUTES.legal}
            getComponent={() =>
                import("./pages/Legal").then((module) => module.default)
            }
        />

        <AsyncRoute
            path={ROUTES.videos}
            getComponent={() =>
                import("./pages/Videos").then((module) => module.default)
            }
        />

        <AsyncRoute
            path={ROUTES.audios}
            getComponent={() =>
                import("./pages/Audios").then((module) => module.default)
            }
        />
    </Router>
);

render(<App />, document.body);
