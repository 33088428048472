import { h, FunctionComponent as F } from "preact";

const LoadingSpinner: F = () => (
    <div className="spinner">
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div></div>
);

export default LoadingSpinner;
