import { h, FunctionComponent as F } from "preact";
import { variables } from "../theme/variables";

interface IProps {
    src: string;
    color?: string;
}

const VideoPlayer: F<IProps> = (props) => {
    const color = props.color
        ? props.color
        : variables.color?.highlight?.substring(1);

    const config = `&color=${color}&title=0&byline=0&portrait=0`;

    return (
        <div style="padding: 56.25% 0 0 0; position:relative;">
            <iframe
                src={props.src + config}
                style="position:absolute;top:0;left:0;width:100%;height:100%;"
                frameBorder="0"
                // @ts-ignore
                allow="autoplay; fullscreen; picture-in-picture"
            ></iframe>
        </div>
    );
};

export default VideoPlayer;
