import { h, FunctionComponent as F } from "preact";
import { variables } from "../theme/variables";

interface IProps {
    text: string;
}

const PageHeadline: F<IProps> = (props) => {
    const { arrowLeft, arrowRight } = variables.char;

    return (
        <h1 className="headline page-headline">
            {props.text}
            <span style={{ color: variables.color.highlight }}>.</span>
        </h1>
    );
};

export default PageHeadline;
