import { h, FunctionComponent as F, Fragment } from "preact"

import img1 from "../assets/images/front_1.jpg"
import img2 from "../assets/images/front_2.jpg"
import img3 from "../assets/images/front_3.jpg"
import img4 from "../assets/images/front_4.jpg"

import video_teaser_img from "../assets/images/mareike_schaefer_video_page_teaser.jpg"
import audioMain from "../assets/audios/Mareike_Do-It.mp3"

import Footer from "./Footer"
import { variables } from "../theme/variables"
import { ROUTES } from "../shared/routes"
import AudioPlayer from "./AudioWavePlayer"
import Container from "./Container"
import { useRef } from "preact/hooks"
import PrimaryLink from "./PrimaryLink"
import { useIsTouch } from "../hooks/useIsTouch"

const Main: F = () => {
    const galerieRef = useRef(null)
    const isTouch = useIsTouch()

    return (
        <Fragment>
            <Container>
                <AudioPlayer audio={audioMain} identifier="home" />
                <PrimaryLink text="audio" arrowRight route={ROUTES.audios} />
            </Container>

            <a href={ROUTES.galerie} ref={galerieRef}>
                <section
                    className="img-grid img-grid-start  container marginTop"
                    data-tip={
                        !isTouch
                            ? `Zur Gallerie ${variables.char.arrowRight}`
                            : null
                    }
                >
                    <img className="img no-grayscale" src={img1} alt="Tough" />
                    <img
                        className="img"
                        src={img2}
                        alt="Vertrauensvolles lächeln, abgewinkelter Kopf"
                    />
                    <img
                        className="img"
                        src={img3}
                        alt="Geheimnisvoll, stark, schreitend, stolz"
                    />
                    <img
                        className="img"
                        src={img4}
                        alt="Lächeln, Nasenring und dunkle Lippen"
                    />
                </section>
            </a>

            <a href={ROUTES.videos}>
                <section
                    className="container marginTopLarge img-video-container"
                    data-tip={`Zu den Videos ${variables.char.arrowRight}`}
                >
                    <img className="img " src={video_teaser_img} />
                    <span class="play-button" />
                </section>
            </a>

            <Container>
                <PrimaryLink text="video" arrowRight route={ROUTES.videos} />
            </Container>

            <Footer />
        </Fragment>
    )
}

export default Main
