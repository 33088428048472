import { FunctionComponent as FC, h } from "preact";
import { useEffect } from "preact/hooks";
import Header from "../components/Header";
import Main from "../components/Main";
import { hoverText } from "../scripts/text";

const Home: FC = () => {
    useEffect(() => {
        hoverText();
    }, []);

    return (
        <div>
            <Header />
            <Main />
        </div>
    );
};

export default Home;
