import { h, FunctionComponent as F } from "preact";

// TODO: use container everywhere
interface IProps {
    className?: string;
}

const Container: F<IProps> = (props) => {
    const classes = [props.className, "container"].join(" ");

    return <section className={classes}>{props.children}</section>;
};

export default Container;
