import { h, FunctionComponent as F } from "preact";
import { variables } from "../theme/variables";

interface IProps {
    route?: string;
    text?: string;
    arrowLeft?: boolean;
    arrowRight?: boolean;
    download?: string;
    open?: boolean;
}

const PrimaryLink: F<IProps> = (props) => {
    const { arrowLeft, arrowRight } = variables.char;

    return (
        <a
            href={props.route}
            className="link link-primary"
            download={props.download ? props.download : null}
            target={props.open ? "_blank" : null}
        >
            {props.arrowLeft ? arrowLeft : ""} {props.text}{" "}
            {props.arrowRight ? arrowRight : ""}
            {props.children}
        </a>
    );
};

export default PrimaryLink;
