import { h, FunctionComponent as F } from "preact";
import { ROUTES } from "../shared/routes";
import { variables } from "../theme/variables";
import PrimaryLink from "./PrimaryLink";

const Header: F = () => (
    <header className="header">
        <div>
            <h1 className="headline">
                Mareike Schaefer
                <span style={{ color: variables.color.highlight }}>.</span>
            </h1>

            <a className="link" href={ROUTES.about}>
                <p className="sub-headline">
                    Schauspielerin &amp; Sprecherin
                    <br />
                    Die beste Wahl ist, frei zu fliegen
                    <br />
                    <PrimaryLink text="about" arrowRight route={ROUTES.about} />
                </p>
            </a>
        </div>
    </header>
);

export default Header;
