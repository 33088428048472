import { h, FunctionComponent as F } from "preact";
import { ROUTES } from "../shared/routes";
import Container from "./Container";
import PrimaryLink from "./PrimaryLink";

const Footer: F = () => ( 
    <footer className="footer paddingBottom">
        <Container>
            <PrimaryLink text="impressum" route={ROUTES.legal} arrowRight />
        </Container>
    </footer>
);

export default Footer;
