import { h, FunctionComponent as F } from "preact";
import { video } from "../pages/Videos";
import Container from "./Container";
import Description from "./Description";
import VideoPlayer from "./VideoPlayer";

interface IProps {
    video: video;
}

const VideoSection: F<IProps> = (props) => {
    const { video } = props;

    return (
        <Container className="video-section">
            <VideoPlayer src={video.src} />

            <Description text1={video.text1} text2={video.text2} />
        </Container>
    );
};

export default VideoSection;
